import axios, {AxiosResponse} from "axios";
import {useCallback, useMemo, useRef, useState} from "react";
import jwt_decode from "jwt-decode";

import {useSelector} from "react-redux";
import {AuthorizationAuthValue, authorizationContextValue} from "../auth/AuthorizationContext";
import {useRefresh} from "../auth/authenticationUtils";
import {logEvent} from "firebase/analytics";
import {analytics} from "../firebase";
import {FOOTER_SINISTRA, LOG_ERROR} from "../registro/Analytics/RegistroAnalytics";

type AuthOptions = {
    auth: true,
    tokenOptions: {
        tokenStorageName: string,
        shouldRefresh: boolean
    }
}

type NoAuthOptions = {
    auth: false,
    tokenOptions?: never
}

type Options = AuthOptions | NoAuthOptions

export const defaultGetToken = () => localStorage.getItem('token')?.slice(1,-1)

export const getToken = (tokenStorageName:string) => localStorage.getItem(tokenStorageName)?.slice(1,-1)

const noAuthConfig =  () => {
    return{headers:{'Content-Type': 'application/json'}}
}

const dynamicFileConfig = (token:string|undefined|null) => {
    if(token!== null && token !== undefined) return {headers:{ 'Content-Type': 'form-data', 'Authorization': `Bearer ${token}`,'User-Key': localStorage.getItem("user-key")}}
    return {headers:{ 'Content-Type': 'application/json'}}
}

const authConfig = () => {
    return {headers:{ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')?.slice(1,-1)}`}}
}

const dynamicAuthConfig = (token:string|undefined|null) => {
    if(token!== null && token !== undefined) return {headers:{ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,'User-Key': localStorage.getItem("user-key")}}
    return {headers:{ 'Content-Type': 'application/json'}}
}

const checkTokenValidity = () => {
    const rawToken = localStorage.getItem('token')?.slice(1,-1);
    if(rawToken){
        const decodedToken = jwt_decode(rawToken);
        // @ts-ignore
        const {exp} = decodedToken;
        const timeNow = Date.now();

        return timeNow/1000 < exp;
    }

    return false;
}

export function usePost<T=any>(authorization:AuthorizationAuthValue =authorizationContextValue){
    const [loading, setLoading] = useState<boolean|undefined>()
    // @ts-ignore
    const refresh = useRefresh(authorization.tokenStorageName);
    const post = useCallback(async (url:string, data:any={} ,getToken:()=>string|null|undefined =() => localStorage.getItem(authorization.tokenStorageName?? "")?.slice(1,-1)) =>{
        setLoading(true)
        try{
            const response = await axios.post(url,data,authorization.auth ? dynamicAuthConfig(getToken()) : noAuthConfig())
            setLoading(false)
            return response

        }catch(reason){
            if(reason.response.status === 401 && authorization.auth){
                await refresh()
                const response = await axios.post(url,data,authorization.auth ? dynamicAuthConfig(getToken()) : noAuthConfig())
                setLoading(false)
                return response
            }else{
                setLoading(false)
                logEvent(analytics,LOG_ERROR, {
                    error_code: reason
                } )
                throw reason
            }
        }
    },[refresh,authorization.tokenStorageName, authorization.auth])

    return useMemo(()=>{
        return {post,loading}
    },[post,loading])

}

export function useGet<T=any>(authorization:AuthorizationAuthValue =authorizationContextValue){
    const hasBeenCalledAtLeastOnce = useRef(false)
    const [loading, setLoading] = useState<boolean>(false)
    // @ts-ignore
    const refresh = useRefresh(authorization.tokenStorageName);
    // @ts-ignore
    const authSlice = useSelector(state=>state.auth)

    const get = useCallback(async (url:string, queryParams:object={}, getToken: ()=>string|null|undefined = () => localStorage.getItem(authorization.tokenStorageName?? "")?.slice(1,-1)):Promise<AxiosResponse<T>> =>{
        hasBeenCalledAtLeastOnce.current = true;
        setLoading(true)
        try{
            const response = await axios.get(addQueryParams(url, queryParams),authorization.auth ? dynamicAuthConfig(getToken()) : noAuthConfig())
            setLoading(false)
            return response
        }catch(reason){
            if(reason.response.status === 401 && authorization.auth){
                await refresh()
                const response = await axios.get(addQueryParams(url, queryParams),authorization.auth ? dynamicAuthConfig(getToken()) : noAuthConfig())
                setLoading(false)
                return response

            }else{
                setLoading(false)
                logEvent(analytics,LOG_ERROR, {
                    error_code: reason
                } )
                throw reason
            }

        }


    },[authSlice])
    return {get, loading,hasBeenCalledAtLeastOnce}
}

export function usePatch(auth=true){
    const [loading, setLoading] = useState<boolean>(false)

    const patch = async (url:string, data:any ) =>{
        setLoading(true)
        return await axios.patch(url,data,((auth) ? authConfig() : noAuthConfig())).then(response => {
            setLoading(false)
            return response
        }).catch(reason =>
        {
            setLoading(false)
            logEvent(analytics,LOG_ERROR, {
                error_code: reason
            } )
            throw reason
        })
    }

    return {patch, loading}

}

export function usePut(auth=true){
    const [loading, setLoading] = useState<boolean>(false)

    const put = async (url:string, data:any ) =>{
        setLoading(true)
        return await axios.put(url,data,((auth) ? authConfig() : noAuthConfig())).then(response => {
            setLoading(false)
            return response
        }).catch(reason =>
        {
            setLoading(false)
            logEvent(analytics,LOG_ERROR, {
                error_code: reason
            } )
            throw reason
        })
    }

    return {put, loading}

}

export function useDelete(auth=true){
    const [loading, setLoading] = useState<boolean>(false)
    const del = useCallback(async (url:string) =>{
        setLoading(true)
        return await axios.delete(url,((auth) ? authConfig() : noAuthConfig())).then(response => {
            setLoading(false)
            return response
        }).catch(reason =>
        {
            setLoading(false)
            throw reason
        })
    },[auth])

    return useMemo(()=>{return {del, loading}},[del,loading])

}

export function usePostFile(authorization:AuthorizationAuthValue =authorizationContextValue){
    const [loading, setLoading] = useState<boolean>(false)

    const postFile = async (url:string,files:any[], getToken: ()=>string|null|undefined = () => localStorage.getItem(authorization.tokenStorageName?? "",)?.slice(1,-1),IsMultiple?:boolean) =>{
        setLoading(true)
        const formData = new FormData();
        let name:string = 'file';
        if (IsMultiple) {
            name = 'files'
            files.forEach((file, index)  =>{
                formData.append(`${name}[${index}]`, file.file)
            })
        }else{
            formData.append("file", files[0].file)
        }
        return await axios.post(url,formData, dynamicFileConfig(getToken())).then(response => {
            setLoading(false)
            return response
        }).catch(reason =>
        {
            setLoading(false)
            throw reason
        })
    }

    return {postFile, loading}

}


const addQueryParams= (url:string,queryParamsObject:object) =>{
    if(Object.entries(queryParamsObject).length ===0) return url;

    let newUrl = `${url}?`;
    Object.entries(queryParamsObject).forEach(([key,value]) => {
        if(value!==null && value!==undefined && value!=="") newUrl = newUrl.concat(`${key}=${value}&`)
    })
    return newUrl;
}
