import {toast} from "react-toastify";

export function toastOnSuccess (message:string) {
    return   toast.success(message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        icon: true
    })
}

export function toastOnError (message:string) {
    return toast.error(message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        icon: true

    })
}

export function toastOnWarn (message:string) {
    return toast.warning(message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        icon: true
    })
}

export function toastOnInfo (message:string) {
    return toast.info(message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        icon: true
    })
}

export function toastOnCustom (message:string) {
    return toast.dark(message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        icon: true
    })
}